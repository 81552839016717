import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function HomeSlider() {
    // const imageStyle ={
    //         backgorundImage: '',
    // }
    return (
        
        <>
            <div className="tiny-slider arrow-hover overflow-hidden home-slider-main">
                <div>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={4}
                        autoplay={true}
                        navigation={true}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div>
                                <div className="card border border-2 border-dashed h-150px shadow-none d-flex align-items-center justify-content-center text-center">
                                    <div>
                                        <span className="stretched-link btn btn-light rounded-circle icon-md" href="#!"><i className="fa-solid fa-plus"></i></span>
                                        <h6 className="mt-2 mb-0 small">Post a Story</h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className="card card-overlay-bottom border-0 position-relative h-150px" style={{backgroundImage: "url('public-assets/images/post/1by1/02.jpg')"}}>
                                    <div className="card-img-overlay d-flex align-items-center p-2">
                                        <div className="w-100 mt-auto">
                                            <span className="stretched-link text-white small">Judy Nguyen</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className="card card-overlay-bottom border-0 position-relative h-150px" style={{backgroundImage: "url('public-assets/images/post/1by1/03.jpg')"}}>
                                    <div className="card-img-overlay d-flex align-items-center p-2">
                                        <div className="w-100 mt-auto">
                                            <span className="stretched-link text-white small">Judy Nguyen</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className="card card-overlay-bottom border-0 position-relative h-150px" style={{backgroundImage: "url('public-assets/images/post/1by1/04.jpg')"}}>
                                    <div className="card-img-overlay d-flex align-items-center p-2">
                                        <div className="w-100 mt-auto">
                                            <span className="stretched-link text-white small">Judy Nguyen</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className="card card-overlay-bottom border-0 position-relative h-150px" style={{backgroundImage: "url('public-assets/images/post/1by1/02.jpg')"}}>
                                    <div className="card-img-overlay d-flex align-items-center p-2">
                                        <div className="w-100 mt-auto">
                                            <span className="stretched-link text-white small">Judy Nguyen</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}


export default HomeSlider;