import React from "react";
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";

function ResetPass(){
    return(
        <>
            <div className="container row-middle">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                        <form className="form">
                        <div className="mb-4">
                                <label className="d-block">Password</label>
                                <div className="input-group">
                                    <input type={'password'} className="form-control" />
                                    <span className="input-group-text"><i class="bi bi-eye-fill"></i></span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="d-block">Confirm-Password</label>
                                <div className="input-group">
                                    <input type={'password'} className="form-control" />
                                    <span className="input-group-text"><i class="bi bi-eye-fill"></i></span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Button variant="contained" size="medium">Reset Password</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPass;