import React from "react";
import { NavLink } from "react-router-dom";




function About(){
    return(
        <>
            <NavLink to={"/"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}> home</NavLink>
            <NavLink to={"/about"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}> about us</NavLink>
            <h1>About us</h1> 
        </>
    )
}


export default About;