import React from "react";
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";

function ForgotPassword(){
    return(
        <>
            <div className="container row-middle">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                        <form className="form">
                            <div className="mb-3">
                                <label className="d-block">Enter Email ID</label>
                                <input type={'email'} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <NavLink to="/reset-password">
                                    <Button variant="contained" size="medium">Reset Password</Button>
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;