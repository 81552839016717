import React from "react";
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";

function Loginform(){
    return(
        <>
            <div className="container row-middle">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                        <form className="form">
                            <div className="mb-3">
                                <label className="d-block">Username</label>
                                <input type='text' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="d-block">Password</label>
                                <input type='password' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <NavLink to="/about">
                                    <label className="d-block text-end">Forgot Password</label>
                                </NavLink>
                            </div>
                            <div className="mb-3">
                                <Button variant="contained" size="medium">Login</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Loginform;