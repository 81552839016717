import React from "react";
import { NavLink } from "react-router-dom";
import HomeSlider from "../inclide/home-slider";
import HomeModal from "./models/homemodal";




function Home() {
    

    return (
        <>

            <NavLink to={"/"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}> home</NavLink>
            <NavLink to={"/about"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}> about us</NavLink>
            <main>

                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-3">
                            <div className="d-flex align-items-center d-lg-none">
                                <button className="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                                    <i className="btn btn-primary fw-bold fa-solid fa-sliders-h"></i>
                                    <span className="h6 mb-0 fw-bold d-lg-none ms-2">My profile</span>
                                </button>
                            </div>
                            <nav className="navbar navbar-expand-lg mx-0">
                                <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                                    <div className="offcanvas-header">
                                        <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body d-block px-2 px-lg-0">
                                        <div className="card overflow-hidden">
                                            <div className="h-50px"></div>
                                            <div className="card-body pt-0">
                                                <div className="text-center">
                                                    <div className="avatar avatar-lg mt-n5 mb-3">
                                                        <span href="#!"><img className="avatar-img rounded border border-white border-3" src="public-assets/images/avatar/07.jpg" alt="" /></span>
                                                    </div>
                                                    <h5 className="mb-0"> <span href="#!">Sam Lanson </span> </h5>
                                                    <small>Web Developer at Webestica</small>
                                                    <p className="mt-3">I'd love to change the world, but they won’t give me the source code.</p>
                                                    <div className="hstack gap-2 gap-xl-3 justify-content-center">
                                                        <div>
                                                            <h6 className="mb-0">256</h6>
                                                            <small>Post</small>
                                                        </div>
                                                        <div className="vr"></div>
                                                        <div>
                                                            <h6 className="mb-0">2.5K</h6>
                                                            <small>Followers</small>
                                                        </div>
                                                        <div className="vr"></div>
                                                        <div>
                                                            <h6 className="mb-0">365</h6>
                                                            <small>Following</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <ul className="nav nav-link-secondary flex-column fw-bold gap-2">
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="my-profile.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/home-outline-filled.svg" alt="" /><span>Feed </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="my-profile-connections.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/person-outline-filled.svg" alt="" /><span>Connections </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="blog.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/earth-outline-filled.svg" alt="" /><span>Latest News </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="events.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/calendar-outline-filled.svg" alt="" /><span>Events </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="groups.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/chat-outline-filled.svg" alt="" /><span>Groups </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="notifications.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/notification-outlined-filled.svg" alt="" /><span>Notifications </span></span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link" href="settings.html"> <img className="me-2 h-20px fa-fw" src="public-assets/images/icon/cog-outline-filled.svg" alt="" /><span>Settings </span></span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-footer text-center py-2">
                                                <span className="btn btn-link btn-sm" href="my-profile.html">View Profile </span>
                                            </div>
                                        </div>
                                        <ul className="nav small mt-4 justify-content-center lh-1">
                                            <li className="nav-item">
                                                <span className="nav-link" href="my-profile-about.html">About</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className="nav-link" href="settings.html">Settings</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className="nav-link">Support </span>
                                            </li>
                                            <li className="nav-item">
                                                <span className="nav-link" target="_blank" href="docs/index.html">Docs </span>
                                            </li>
                                            <li className="nav-item">
                                                <span className="nav-link" href="help.html">Help</span>
                                            </li>
                                            <li className="nav-item">
                                                <span className="nav-link" href="privacy-and-terms.html">Privacy  terms</span>
                                            </li>
                                        </ul>
                                        <p className="small text-center mt-1">©2022 <span className="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </span></p>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-md-8 col-lg-6 vstack gap-4">
                            <HomeSlider />
                            <HomeModal />
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar avatar-story me-2">
                                                <span> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <div className="nav nav-divider">
                                                    <h6 className="nav-item card-title mb-0"> <span href="#!"> Lori Ferguson </span></h6>
                                                    <span className="nav-item small"> 2hr</span>
                                                </div>
                                                <p className="mb-0 small">Web Developer at Webestica</p>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <span className="text-secondary btn btn-secondary-soft-hover py-1 px-2">
                                                <i className="bi bi-three-dots"></i>
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p>I'm thrilled to share that I've completed a graduate certificate course in project management with the president's honor roll.</p>
                                    <img className="card-img" src="public-assets/images/post/3by2/01.jpg" alt="Post" />
                                    <ul className="nav nav-stack py-3 small">
                                        <li className="nav-item">
                                            <span className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                        <li className="nav-item dropdown ms-sm-auto">
                                            <span className="nav-link mb-0" href="#" id="cardShareAction" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="d-flex mb-3">
                                        <div className="avatar avatar-xs me-2">
                                            <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/12.jpg" alt="" /> </span>
                                        </div>
                                        <form className="w-100">
                                            <textarea data-autoresize className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                                        </form>
                                    </div>
                                    <ul className="comment-wrap list-unstyled">
                                        <li className="comment-item">
                                            <div className="d-flex position-relative">
                                                <div className="avatar avatar-xs">
                                                    <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /></span>
                                                </div>
                                                <div className="ms-2">
                                                    <div className="bg-light rounded-start-top-0 p-3 rounded">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="mb-1"> <span href="#!"> Frances Guerrero </span></h6>
                                                            <small className="ms-2">5hr</small>
                                                        </div>
                                                        <p className="small mb-0">Removed demands expense account in outward tedious do. Particular way thoroughly unaffected projection.</p>
                                                    </div>
                                                    <ul className="nav nav-divider py-2 small">
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Like (3)</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Reply</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> View 5 replies</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="comment-item-nested list-unstyled">
                                                <li className="comment-item">
                                                    <div className="d-flex">
                                                        <div className="avatar avatar-xs">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/06.jpg" alt="" /></span>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="mb-1"> <span href="#!"> Lori Stevens </span> </h6>
                                                                    <small className="ms-2">2hr</small>
                                                                </div>
                                                                <p className="small mb-0">See resolved goodness felicity shy civility domestic had but Drawings offended yet answered Jennings perceive.</p>
                                                            </div>
                                                            <ul className="nav nav-divider py-2 small">
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Like (5)</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Reply</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="comment-item">
                                                    <div className="d-flex">
                                                        <div className="avatar avatar-story avatar-xs">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/07.jpg" alt="" /></span>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="mb-1"> <span href="#!"> Billy Vasquez </span> </h6>
                                                                    <small className="ms-2">15min</small>
                                                                </div>
                                                                <p className="small mb-0">Wishing calling is warrant settled was lucky.</p>
                                                            </div>
                                                            <ul className="nav nav-divider py-2 small">
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Like</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Reply</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center mb-3 ms-5" data-bs-toggle="button" aria-pressed="true">
                                                <div className="spinner-dots me-2">
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                </div>
                                                Load more replies
                                            </span>
                                        </li>
                                        <li className="comment-item">
                                            <div className="d-flex">
                                                <div className="avatar avatar-xs">
                                                    <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /></span>
                                                </div>
                                                <div className="ms-2">
                                                    <div className="bg-light p-3 rounded">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="mb-1"> <span href="#!"> Frances Guerrero </span> </h6>
                                                            <small className="ms-2">4min</small>
                                                        </div>
                                                        <p className="small mb-0">Removed demands expense account in outward tedious do. Particular way thoroughly unaffected projection.</p>
                                                    </div>
                                                    <ul className="nav nav-divider pt-2 small">
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Like (1)</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Reply</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> View 6 replies</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer border-0 pt-0">
                                    <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center" data-bs-toggle="button" aria-pressed="true">
                                        <div className="spinner-dots me-2">
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                        </div>
                                        Load more comments
                                    </span>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/logo/12.svg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"><span href="#!"> Bootstrap: Front-end framework </span></h6>
                                                <span href="#!" className="mb-0 text-body">Sponsored <i className="bi bi-info-circle ps-1" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="You're seeing this ad because your activity meets the intended audience of our site."></i> </span>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots"></i>
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="mb-0">Quickly design and customize responsive mobile-first sites with Bootstrap.</p>
                                </div>
                                <img src="public-assets/images/post/3by2/02.jpg" alt="" />
                                <div className="card-footer border-0 d-flex justify-content-between align-items-center">
                                    <p className="mb-0">Currently v5.1.3 </p>
                                    <span className="btn btn-primary-soft btn-sm" href="#"> Download now </span>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"> <span href="#"> Judy Nguyen </span></h6>
                                                <div className="nav nav-divider">
                                                    <p className="nav-item mb-0 small">Web Developer at Webestica</p>
                                                    <span className="nav-item small" data-bs-toggle="tooltip" data-bs-placement="top" title="Public"> <i className="bi bi-globe"></i> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction3" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots"></i>
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction3">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p>I'm so privileged to be involved in the <span href="#!">@bootstrap </span>hiring process! Interviewing with their team was fun and I hope this can be a valuable resource for folks! <span href="#!"> #inclusivebusiness</span> <span href="#!"> #internship</span> <span href="#!"> #hiring</span> <span href="#"> #apply </span></p>
                                    <div className="d-flex justify-content-between">
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <span className="h-100" href="assets/images/post/1by1/03.jpg" data-glightbox data-gallery="image-popup">
                                                    {/* <img className="rounded img-fluid" src="public-assets/images/post/1by1/03.jpg" alt="Image" /> */}
                                                </span>
                                            </div>
                                            <div className="col-6">
                                                <span href="assets/images/post/3by2/01.jpg" data-glightbox data-gallery="image-popup">
                                                    {/* <img className="rounded img-fluid" src="public-assets/images/post/3by2/01.jpg" alt="Image" /> */}
                                                </span>
                                                <div className="position-relative bg-dark mt-3 rounded">
                                                    <div className="hover-actions-item position-absolute top-50 start-50 translate-middle z-index-9">
                                                        <span className="btn btn-link text-white" href="#"> View all </span>
                                                    </div>
                                                    <span href="assets/images/post/3by2/02.jpg" data-glightbox data-gallery="image-popup">
                                                        <img className="img-fluid opacity-50 rounded" src="public-assets/images/post/3by2/02.jpg" alt="" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nav nav-stack py-3 small">
                                        <li className="nav-item">
                                            <span className="nav-link active text-secondary" href="#!"> <i className="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i> Louis, Billy and 126 others </span>
                                        </li>
                                        <li className="nav-item ms-sm-auto">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-1 mb-3">
                                        <li className="nav-item">
                                            <span className="nav-link mb-0 active" href="#!"> <i className="bi bi-heart pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <span href="#" className="nav-link mb-0" id="cardShareAction4" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction4">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-send-fill pe-1"></i>Send</span>
                                        </li>
                                    </ul>
                                    <ul className="comment-wrap list-unstyled">
                                        <li className="comment-item">
                                            <div className="d-flex">
                                                <div className="avatar avatar-xs">
                                                    <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /> </span>
                                                </div>
                                                <div className="ms-2">
                                                    <div className="bg-light rounded-start-top-0 p-3 rounded">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="mb-1"> <span href="#!"> Frances Guerrero </span></h6>
                                                            <small className="ms-2">5hr</small>
                                                        </div>
                                                        <p className="small mb-0">Removed demands expense account in outward tedious do. Particular way thoroughly unaffected projection.</p>
                                                    </div>
                                                    <ul className="nav nav-divider py-2 small">
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Like (3)</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Reply</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> View 5 replies</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="comment-item-nested list-unstyled">
                                                <li className="comment-item">
                                                    <div className="d-flex">
                                                        <div className="avatar avatar-xs">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/06.jpg" alt="" /></span>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="mb-1"> <span href="#!"> Lori Stevens </span> </h6>
                                                                    <small className="ms-2">2hr</small>
                                                                </div>
                                                                <p className="small mb-0">See resolved goodness felicity shy civility domestic had but Drawings offended yet answered Jennings perceive.</p>
                                                            </div>
                                                            <ul className="nav nav-divider py-2 small">
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Like (5)</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Reply</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="comment-item">
                                                    <div className="d-flex">
                                                        <div className="avatar avatar-xs">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/07.jpg" alt="" /></span>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="mb-1"> <span href="#!"> Billy Vasquez </span> </h6>
                                                                    <small className="ms-2">15min</small>
                                                                </div>
                                                                <p className="small mb-0">Wishing calling is warrant settled was lucky.</p>
                                                            </div>
                                                            <ul className="nav nav-divider py-2 small">
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Like</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Reply</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center mb-3 ms-5" data-bs-toggle="button" aria-pressed="true">
                                                <div className="spinner-dots me-2">
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                </div>
                                                Load more replies
                                            </span>
                                        </li>
                                        <li className="comment-item">
                                            <div className="d-flex">
                                                <div className="avatar avatar-xs">
                                                    <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /></span>
                                                </div>
                                                <div className="ms-2">
                                                    <div className="bg-light p-3 rounded">
                                                        <div className="d-flex justify-content-center">
                                                            <h6 className="mb-1"> <span href="#!"> Frances Guerrero </span> </h6>
                                                            <small className="ms-2">4min</small>
                                                        </div>
                                                        <p className="small mb-0">Congratulations:</p>
                                                        <div className="card p-2 border border-2 rounded mt-2 shadow-none">
                                                            <img src="public-assets/images/elements/12.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <ul className="nav nav-divider pt-2 small">
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Like (1)</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Reply</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> View 6 replies</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer border-0 pt-0">
                                    <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center" data-bs-toggle="button" aria-pressed="true">
                                        <div className="spinner-dots me-2">
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                        </div>
                                        Load more comments
                                    </span>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/logo/13.svg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"> <span href="#!"> Apple Education </span></h6>
                                                <p className="mb-0 small">9 November at 23:29</p>
                                            </div>
                                        </div>
                                        <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction5" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots"></i>
                                        </span>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction5">
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body pb-0">
                                    <p>Find out how you can save time in the classNameroom this year. Earn recognition while you learn new skills on iPad and Mac. Start  recognition your first Apple Teacher badge today!</p>
                                    <ul className="nav nav-stack pb-2 small">
                                        <li className="nav-item">
                                            <span className="nav-link active text-secondary" href="#!"> <i className="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i> Louis, Billy and 126 others </span>
                                        </li>
                                        <li className="nav-item ms-sm-auto">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer py-3">
                                    <ul className="nav nav-fill nav-stack small">
                                        <li className="nav-item">
                                            <span className="nav-link mb-0 active" href="#!"> <i className="bi bi-heart pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <span href="#" className="nav-link mb-0" id="cardShareAction6" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction6">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-send-fill pe-1"></i>Send</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center border-0 pb-0">
                                    <h6 className="card-title mb-0">People you may know</h6>
                                    <button className="btn btn-sm btn-primary-soft"> See all </button>
                                </div>
                                <div className="card-body">
                                    <div className="tiny-slider arrow-hover">
                                        <div className="tiny-slider-inner ms-n4" data-arrow="true" data-dots="false" data-items-xl="3" data-items-lg="2" data-items-md="2" data-items-sm="2" data-items-xs="1" data-gutter="12" data-edge="30">
                                            <div>
                                                <div className="card shadow-none text-center">
                                                    <div className="card-body p-2 pb-0">
                                                        <div className="avatar avatar-xl">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/09.jpg" alt="" /></span>
                                                        </div>
                                                        <h6 className="card-title mb-1 mt-3"> <span href="#!"> Amanda Reed </span></h6>
                                                        <p className="mb-0 small lh-sm">50 mutual connections</p>
                                                    </div>
                                                    <div className="card-footer p-2 border-0">
                                                        <button className="btn btn-sm btn-primary-soft w-100"> Add friend </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="card shadow-none text-center">
                                                    <div className="card-body p-2 pb-0">
                                                        <div className="avatar avatar-story avatar-xl">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/10.jpg" alt="" /></span>
                                                        </div>
                                                        <h6 className="card-title mb-1 mt-3"> <span href="#!"> Larry Lawson </span></h6>
                                                        <p className="mb-0 small lh-sm">33 mutual connections</p>
                                                    </div>
                                                    <div className="card-footer p-2 border-0">
                                                        <button className="btn btn-sm btn-primary-soft w-100"> Add friend </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="card shadow-none text-center">
                                                    <div className="card-body p-2 pb-0">
                                                        <div className="avatar avatar-xl">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/11.jpg" alt="" /></span>
                                                        </div>
                                                        <h6 className="card-title mb-1 mt-3"> <span href="#!"> Louis Crawford </span></h6>
                                                        <p className="mb-0 small lh-sm">45 mutual connections</p>
                                                    </div>
                                                    <div className="card-footer p-2 border-0">
                                                        <button className="btn btn-sm btn-primary-soft w-100"> Add friend </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="card shadow-none text-center">
                                                    <div className="card-body p-2 pb-0">
                                                        <div className="avatar avatar-xl">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/12.jpg" alt="" /></span>
                                                        </div>
                                                        <h6 className="card-title mb-1 mt-3"> <span href="#!"> Dennis Barrett </span></h6>
                                                        <p className="mb-0 small lh-sm">21 mutual connections</p>
                                                    </div>
                                                    <div className="card-footer p-2 border-0">
                                                        <button className="btn btn-sm btn-primary-soft w-100"> Add friend </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"> <span href="#!"> All in the Mind </span></h6>
                                                <p className="mb-0 small">9 November at 23:29</p>
                                            </div>
                                        </div>
                                        <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction7" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots"></i>
                                        </span>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction7">
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body pb-0">
                                    <p>How do you protect your business against cyber-crime?</p>
                                    <div className="vstack gap-2">
                                        <div>
                                            <input type="radio" className="btn-check" name="poll" id="option" />
                                            <label className="btn btn-outline-primary w-100" for="option">We have cybersecurity insurance coverage</label>
                                        </div>
                                        <div>
                                            <input type="radio" className="btn-check" name="poll" id="option2" />
                                            <label className="btn btn-outline-primary w-100" for="option2">Our dedicated staff will protect us</label>
                                        </div>
                                        <div>
                                            <input type="radio" className="btn-check" name="poll" id="option3" />
                                            <label className="btn btn-outline-primary w-100" for="option3">We give regular training for best practices</label>
                                        </div>
                                        <div>
                                            <input type="radio" className="btn-check" name="poll" id="option4" />
                                            <label className="btn btn-outline-primary w-100" for="option4">Third-party vendor protection</label>
                                        </div>
                                    </div>
                                    <ul className="nav nav-divider mt-2 mb-0">
                                        <li className="nav-item">
                                            <span className="nav-link" href="#">263 votes</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link" href="#">2d left</span>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-stack pb-2 small mt-4">
                                        <li className="nav-item">
                                            <span className="nav-link active text-secondary" href="#!"> <i className="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i> Louis, Billy and 126 others </span>
                                        </li>
                                        <li className="nav-item ms-sm-auto">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer py-3">
                                    <ul className="nav nav-fill nav-stack small">
                                        <li className="nav-item">
                                            <span className="nav-link mb-0 active" href="#!"> <i className="bi bi-heart pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <span href="#" className="nav-link mb-0" id="feedActionShare6" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare6">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-send-fill pe-1"></i>Send</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"> <span href="#!"> All in the Mind </span></h6>
                                                <p className="mb-0 small">9 November at 23:29</p>
                                            </div>
                                        </div>
                                        <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction10" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots"></i>
                                        </span>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction10">
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body pb-0">
                                    <p>How do you protect your business against cyber-crime?</p>
                                    <div className="card card-body mt-4">
                                        <div className="d-sm-flex justify-content-sm-between align-items-center">
                                            <span className="small">16/20 responded</span>
                                            <span className="small">Results not visible to participants</span>
                                        </div>
                                        <div className="vstack gap-4 gap-sm-3 mt-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="overflow-hidden w-100 me-3">
                                                    <div className="progress bg-primary bg-opacity-10 position-relative">
                                                        <div className="progress-bar bg-primary bg-opacity-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <span className="position-absolute pt-1 ps-3 fs-6 fw-normal text-truncate w-100">We have cybersecurity insurance coverage </span>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0">25%</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="overflow-hidden w-100 me-3">
                                                    <div className="progress bg-primary bg-opacity-10 position-relative" >
                                                        <div className="progress-bar bg-primary bg-opacity-25" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <span className="position-absolute pt-1 ps-3 fs-6 fw-normal text-truncate w-100">Our dedicated staff will protect us</span>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0">15%</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="overflow-hidden w-100 me-3">
                                                    <div className="progress bg-primary bg-opacity-10 position-relative">
                                                        <div className="progress-bar bg-primary bg-opacity-25" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <span className="position-absolute pt-1 ps-3 fs-6 fw-normal text-truncate w-100">We give regular training for best practices</span>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0">10%</div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="overflow-hidden w-100 me-3">
                                                    <div className="progress bg-primary bg-opacity-10 position-relative" >
                                                        <div className="progress-bar bg-primary bg-opacity-25" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                        <span className="position-absolute pt-1 ps-3 fs-6 fw-normal text-truncate w-100">Third-party vendor protection</span>
                                                    </div>
                                                </div>
                                                <div className="flex-shrink-0">55%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nav nav-divider mt-2 mb-0">
                                        <li className="nav-item">
                                            <span className="nav-link" href="#">263 votes</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link" href="#">2d left</span>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-stack pb-2 small mt-4">
                                        <li className="nav-item">
                                            <span className="nav-link active text-secondary" href="#!"> <i className="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i> Louis, Billy and 126 others </span>
                                        </li>
                                        <li className="nav-item ms-sm-auto">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer py-3">
                                    <ul className="nav nav-fill nav-stack small">
                                        <li className="nav-item">
                                            <span className="nav-link mb-0 active" href="#!"> <i className="bi bi-heart pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <span href="#" className="nav-link mb-0" id="feedActionShare8" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare8">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-send-fill pe-1"></i>Send</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar me-2">
                                                <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/logo/11.svg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <h6 className="card-title mb-0"> <span href="#!"> Webestica </span></h6>
                                                <p className="small mb-0">9 December at 10:00 </p>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction8" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots"></i>
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction8">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="mb-0">The next-generation blog, news, and magazine theme for you to start sharing your content today with beautiful aesthetics! This minimal  clean Bootstrap 5 based theme is ideal for all types of sites that aim to provide users with content. <span href="#!"> #bootstrap</span> <span href="#!"> #webestica </span> <span href="#!"> #getbootstrap</span> <span href="#"> #bootstrap5 </span></p>
                                </div>
                                <span href="#!"> <img src="public-assets/images/post/3by2/03.jpg" alt="" /> </span>
                                <div className="card-body position-relative bg-light">
                                    <span href="#!" className="small stretched-link">https://blogzine.webestica.com</span>
                                    <h6 className="mb-0 mt-1">Blogzine - Blog and Magazine Bootstrap 5 Theme</h6>
                                    <p className="mb-0 small">Bootstrap based News, Magazine and Blog Theme</p>
                                </div>
                                <div className="card-footer py-3">
                                    <ul className="nav nav-fill nav-stack small">
                                        <li className="nav-item">
                                            <span className="nav-link mb-0 active" href="#!"> <i className="bi bi-heart pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <span href="#" className="nav-link mb-0" id="feedActionShare7" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare7">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link mb-0" href="#!"> <i className="bi bi-send-fill pe-1"></i>Send</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar avatar-story me-2">
                                                <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/12.jpg" alt="" /> </span>
                                            </div>
                                            <div>
                                                <div className="nav nav-divider">
                                                    <h6 className="nav-item card-title mb-0"> <span href="#!"> Joan Wallace </span></h6>
                                                    <span className="nav-item small"> 1day</span>
                                                </div>
                                                <p className="mb-0 small">12 January at 12:00</p>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <span href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots"></i>
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction2">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pb-0">
                                    <p>Comfort reached gay perhaps chamber his <span href="#!">#internship</span>  <span href="#!">#hiring</span> <span href="#!">#apply</span> </p>
                                </div>
                                <div className="overflow-hidden fullscreen-video w-100">
                                    <div className="player-wrapper overflow-hidden">
                                        {/* 
                                    <video className="player-html" controls crossorigin="anonymous" poster="assets/images/videos/poster.jpg">
                                    <source src="public-assets/images/videos/video-feed.mp4" type="video/mp4">
                                    </video>
                                    */}
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <ul className="nav nav-stack py-3 small">
                                        <li className="nav-item">
                                            <span className="nav-link active" href="#!"> <i className="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link" href="#!"> <i className="bi bi-chat-fill pe-1"></i>Comments (12)</span>
                                        </li>
                                        <li className="nav-item dropdown ms-sm-auto">
                                            <span className="nav-link mb-0" href="#" id="cardShareAction9" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                                            </span>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction9">
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Send via Direct Message</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark </span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-link fa-fw pe-2"></i>Copy link to post</span></li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-share fa-fw pe-2"></i>Share post via …</span></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Share to News Feed</span></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="d-flex mb-3">
                                        <div className="avatar avatar-xs me-2">
                                            <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/12.jpg" alt="" /> </span>
                                        </div>
                                        <form className="position-relative w-100">
                                            <textarea data-autoresize className="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                                        </form>
                                    </div>
                                    <ul className="comment-wrap list-unstyled mb-0">
                                        <li className="comment-item">
                                            <div className="d-flex">
                                                <div className="avatar avatar-xs">
                                                    <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /></span>
                                                </div>
                                                <div className="ms-2">
                                                    <div className="bg-light rounded-start-top-0 p-3 rounded">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="mb-1"> <span href="#!"> Frances Guerrero </span></h6>
                                                            <small className="ms-2">5hr</small>
                                                        </div>
                                                        <p className="small mb-0">Preference any astonished unreserved Mrs.</p>
                                                    </div>
                                                    <ul className="nav nav-divider py-2 small">
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Like (3)</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> Reply</span>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link" href="#!"> View 5 replies</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="comment-item-nested list-unstyled">
                                                <li className="comment-item">
                                                    <div className="d-flex">
                                                        <div className="avatar avatar-xs">
                                                            <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/06.jpg" alt="" /></span>
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="bg-light p-3 rounded">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="mb-1"> <span href="#!"> Lori Stevens </span> </h6>
                                                                    <small className="ms-2">2hr</small>
                                                                </div>
                                                                <p className="small mb-0">Dependent on so extremely delivered by. Yet no jokes worse her why.</p>
                                                            </div>
                                                            <ul className="nav nav-divider py-2 small">
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Like (5)</span>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <span className="nav-link" href="#!"> Reply</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer border-0 pt-0">
                                    <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center" data-bs-toggle="button" aria-pressed="true">
                                        <div className="spinner-dots me-2">
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                            <span className="spinner-dot"></span>
                                        </div>
                                        Load more comments
                                    </span>
                                </div>
                            </div>
                            <span href="#!" role="button" className="btn btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
                                <span className="load-text"> Load more </span>
                                <div className="load-icon">
                                    <div className="spinner-grow spinner-grow-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <div className="row g-4">
                                <div className="col-sm-6 col-lg-12">
                                    <div className="card">
                                        <div className="card-header pb-0 border-0">
                                            <h5 className="card-title mb-0">Who to follow</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="hstack gap-2 mb-3">
                                                <div className="avatar">
                                                    <span href="#!"><img className="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="" /></span>
                                                </div>
                                                <div className="overflow-hidden">
                                                    <span className="h6 mb-0" href="#!">Judy Nguyen </span>
                                                    <p className="mb-0 small text-truncate">News anchor</p>
                                                </div>
                                                <span className="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i className="fa-solid fa-plus"> </i></span>
                                            </div>
                                            <div className="hstack gap-2 mb-3">
                                                <div className="avatar avatar-story">
                                                    <span href="#!"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="" /> </span>
                                                </div>
                                                <div className="overflow-hidden">
                                                    <span className="h6 mb-0" href="#!">Amanda Reed </span>
                                                    <p className="mb-0 small text-truncate">Web Developer</p>
                                                </div>
                                                <span className="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i className="fa-solid fa-plus"> </i></span>
                                            </div>
                                            <div className="hstack gap-2 mb-3">
                                                <div className="avatar">
                                                    <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/11.jpg" alt="" /> </span>
                                                </div>
                                                <div className="overflow-hidden">
                                                    <span className="h6 mb-0" href="#!">Billy Vasquez </span>
                                                    <p className="mb-0 small text-truncate">News anchor</p>
                                                </div>
                                                <span className="btn btn-primary rounded-circle icon-md ms-auto" href="#"><i className="bi bi-person-check-fill"> </i></span>
                                            </div>
                                            <div className="hstack gap-2 mb-3">
                                                <div className="avatar">
                                                    <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/01.jpg" alt="" /> </span>
                                                </div>
                                                <div className="overflow-hidden">
                                                    <span className="h6 mb-0" href="#!">Lori Ferguson </span>
                                                    <p className="mb-0 small text-truncate">Web Developer at Webestica</p>
                                                </div>
                                                <span className="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i className="fa-solid fa-plus"> </i></span>
                                            </div>
                                            <div className="hstack gap-2 mb-3">
                                                <div className="avatar">
                                                    <span href="#"> <img className="avatar-img rounded-circle" src="public-assets/images/avatar/placeholder.jpg" alt="" /> </span>
                                                </div>
                                                <div className="overflow-hidden">
                                                    <span className="h6 mb-0" href="#!">Carolyn Ortiz </span>
                                                    <p className="mb-0 small text-truncate">News anchor</p>
                                                </div>
                                                <span className="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i className="fa-solid fa-plus"> </i></span>
                                            </div>
                                            <div className="d-grid mt-3">
                                                <span className="btn btn-sm btn-primary-soft" href="#!">View more</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-12">
                                    <div className="card">
                                        <div className="card-header pb-0 border-0">
                                            <h5 className="card-title mb-0">Today’s news</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <h6 className="mb-0"><span href="blog-details.html">Ten questions you should answer truthfully</span></h6>
                                                <small>2hr</small>
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="mb-0"><span href="blog-details.html">Five unbelievable facts about money</span></h6>
                                                <small>3hr</small>
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="mb-0"><span href="blog-details.html">Best Pinterest Boards for learning about business</span></h6>
                                                <small>4hr</small>
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="mb-0"><span href="blog-details.html">Skills that you can learn from business</span></h6>
                                                <small>6hr</small>
                                            </div>
                                            <span href="#!" role="button" className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center" data-bs-toggle="button" aria-pressed="true">
                                                <div className="spinner-dots me-2">
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                    <span className="spinner-dot"></span>
                                                </div>
                                                View all latest news
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}


export default Home;