import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function HomeModal() {
    const [postDailog, setOpen] = React.useState(false);
    const [videoDialog, setOpen2] = React.useState(false);
    const [eventsDialog, setOpen3] = React.useState(false);
    const [createpostDialog, setOpen4] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenvideo = () => {
        setOpen2(true);
    };
    const handleClosevideo = () => {
        setOpen2(false);
    };


    const handleClickOpenevets = () => {
        setOpen3(true);
    };
    const handleCloseevets = () => {
        setOpen3(false);
    };
    
    
    const handleClickOpencreatepost = () => {
        setOpen4(true);
    };
    const handleClosecreatepost = () => {
        setOpen4(false);
    };


    return (
        <>
            <Dialog
                open={postDailog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="fw-600 bb-1px">
                    {"Add post photo"}
                </DialogTitle>
                <DialogContent className="modal-lg">
                    <DialogContentText id="alert-dialog-description">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="d-flex mb-3">
                                    <div class="avatar avatar-xs me-2">
                                        <img class="avatar-img rounded-circle" src="public-assets/images/avatar/03.jpg" alt="" />
                                    </div>
                                    <form class="w-100">
                                        <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                                    </form>
                                </div>

                                <div>
                                    <label class="form-label">Upload attachment</label>
                                    <div class="dropzone dropzone-default card shadow-none dz-clickable" data-dropzone="{&quot;maxFiles&quot;:2}">
                                        <div class="dz-message">
                                            <i class="bi bi-images display-3"></i>
                                            <p>Drag here or click to upload photo.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}><button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal">Cancel</button></Button>
                    <Button onClick={handleClose} autoFocus>
                        <button type="button" class="btn btn-success-soft">Post</button>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={videoDialog}
                onClose={handleClosevideo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="fw-600 bb-1px">
                    {"Add post video"}
                </DialogTitle>
                <DialogContent className="modal-lg">
                    <DialogContentText id="alert-dialog-description">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="d-flex mb-3">
                                    <div class="avatar avatar-xs me-2">
                                        <img class="avatar-img rounded-circle" src="public-assets/images/avatar/03.jpg" alt="video" />
                                    </div>
                                    <form class="w-100">
                                        <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                                    </form>
                                </div>

                                <div>
                                    <label class="form-label">Upload attachment</label>
                                    <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                                        <div class="dz-message">
                                            <i class="bi bi-camera-reels display-3"></i>
                                            <p>Drag here or click to upload video.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosevideo}><button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal">Cancel</button></Button>
                    <Button onClick={handleClosevideo} autoFocus>
                        <button type="button" class="btn btn-success-soft">Post</button>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={eventsDialog}
                onClose={handleCloseevets}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="fw-600 bb-1px">
                    {"Create event"}
                </DialogTitle>
                <DialogContent className="modal-lg">
                    <DialogContentText id="alert-dialog-description">
                        <div class="modal-content">
                            <div class="modal-body">
                                <form class="row g-4">
                                    <div class="col-12">
                                        <label class="form-label">Title</label>
                                        <input type="email" class="form-control" placeholder="Event name here" />
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label">Description</label>
                                        <textarea class="form-control" rows="2" placeholder="Ex: topics, schedule, etc."></textarea>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="form-label">Date</label>
                                        <input type="text" class="form-control flatpickr" placeholder="Select date" />
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="form-label">Time</label>
                                        <input type="text" class="form-control flatpickr" data-enableTime="true" data-noCalendar="true" placeholder="Select time" />
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="form-label">Duration</label>
                                        <input type="email" class="form-control" placeholder="1hr 23m" />
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label">Location</label>
                                        <input type="email" class="form-control" placeholder="Logansport, IN 46947" />
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label">Add guests</label>
                                        <input type="email" class="form-control" placeholder="Guest email" />
                                    </div>
                                    <div class="col-12 mt-3">
                                        <ul class="avatar-group list-unstyled align-items-center mb-0">
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/01.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/02.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/03.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/04.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/05.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/06.jpg" alt="avatar" />
                                            </li>
                                            <li class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="public-assets/images/avatar/07.jpg" alt="avatar" />
                                            </li>
                                            <li class="ms-3">
                                                <small> +50 </small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <label class="form-label">Upload attachment</label>
                                        <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                                            <div class="dz-message">
                                                <i class="bi bi-file-earmark-text display-3"></i>
                                                <p>Drop presentation and document here or click to upload.</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosevideo}><button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal">Cancel</button></Button>
                    <Button onClick={handleClosevideo} autoFocus>
                        <button type="button" class="btn btn-success-soft">Create now</button>
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={createpostDialog}
                onClose={handleClosecreatepost}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="fw-600 bb-1px">
                    {"Create post"}
                </DialogTitle>
                <DialogContent className="modal-lg">
                    <DialogContentText id="alert-dialog-description">
                        <div class="modal-content">
                        <div class="modal-body">
         <div class="d-flex mb-3">
          <div class="avatar avatar-xs me-2">
            <img class="avatar-img rounded-circle" src="public-assets/images/avatar/03.jpg" alt="" />
          </div>
          <form class="w-100">
            <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="4" placeholder="Share your thoughts..." autofocus></textarea>
          </form>
        </div>
        <div class="hstack gap-2">
          <a class="icon-md bg-success bg-opacity-10 text-success rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Photo"> <i class="bi bi-image-fill"></i> </a>
          <a class="icon-md bg-info bg-opacity-10 text-info rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Video"> <i class="bi bi-camera-reels-fill"></i> </a>
          <a class="icon-md bg-danger bg-opacity-10 text-danger rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Events"> <i class="bi bi-calendar2-event-fill"></i> </a>
          <a class="icon-md bg-warning bg-opacity-10 text-warning rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Feeling/Activity"> <i class="bi bi-emoji-smile-fill"></i> </a>
          <a class="icon-md bg-light text-secondary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Check in"> <i class="bi bi-geo-alt-fill"></i> </a>
          <a class="icon-md bg-primary bg-opacity-10 text-primary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Tag people on top"> <i class="bi bi-tag-fill"></i> </a>
        </div>
      </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="row">
                        <div class="col-lg-3">
                            <select class="form-select js-choice choice-select-text-none" data-position="top" data-search-enabled="false">
                                <option value="PB">Public</option>
                                <option value="PV">Friends</option>
                                <option value="PV">Only me</option>
                                <option value="PV">Custom</option>
                            </select>
                        </div>
                        <div className="col-lg-8">
                        <Button onClick={handleClosevideo}><button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"><i class="bi bi-camera-video-fill pe-1"></i> Live video</button></Button>
                        <Button onClick={handleClosevideo} autoFocus>
                            <button type="button" class="btn btn-success-soft">Post</button>
                        </Button>
                        </div>
                    </div>
                    
                </DialogActions>
            </Dialog>


            <div className="card card-body">
                <div className="d-flex mb-3">
                    <div className="avatar avatar-xs me-2">
                        <span > <img className="avatar-img rounded-circle" src="public-assets/images/avatar/03.jpg" alt="" /> </span>
                    </div>
                    <form className="w-100">
                        <textarea className="form-control pe-4 border-0" rows="2" data-autoresize placeholder="Share your thoughts..."></textarea>
                    </form>
                </div>
                <ul className="nav nav-pills nav-stack small fw-normal">
                    <li className="nav-item" onClick={handleClickOpen}>
                        <span className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionPhoto"> <i className="bi bi-image-fill text-success pe-2"></i>Photo</span>
                    </li>
                    <li className="nav-item" onClick={handleClickOpenvideo}>
                        <span className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionVideo"> <i className="bi bi-camera-reels-fill text-info pe-2"></i>Video</span>
                    </li>
                    <li className="nav-item" onClick={handleClickOpenevets}>
                        <span href="#" className="nav-link bg-light py-1 px-2 mb-0" data-bs-toggle="modal" data-bs-target="#modalCreateEvents"> <i className="bi bi-calendar2-event-fill text-danger pe-2"></i>Event </span>
                    </li>
                    <li className="nav-item" onClick={handleClickOpencreatepost}>
                        <span className="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#modalCreateFeed"> <i className="bi bi-emoji-smile-fill text-warning pe-2"></i>Feeling /Activity</span>
                    </li>
                    <li className="nav-item dropdown ms-lg-auto">
                        <span className="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots"></i>
                        </span>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                            <li><span className="dropdown-item" href="#"> <i className="bi bi-envelope fa-fw pe-2"></i>Create a poll</span></li>
                            <li><span className="dropdown-item" href="#"> <i className="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question </span></li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li><span className="dropdown-item" href="#"> <i className="bi bi-pencil-square fa-fw pe-2"></i>Help</span></li>
                        </ul>
                    </li>
                </ul>
            </div>

        </>
    )
};

export default HomeModal;