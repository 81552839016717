import React from 'react'
import { Button } from '@mui/material';


function Registration(){
    let password = 'password';
    
    return(
        <>
            <div className="container row-middle">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <form className="form">
                           <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Firsname</label>
                                    <input  type={'text'} className={'form-control'} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Lastname</label>
                                    <input  type={'text'} className={'form-control'} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Email</label>
                                    <input  type={'email'} className={'form-control'} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Mobile No</label>
                                    <input  type={'tel'} className={'form-control'} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Confirm-Password</label>
                                    <div className="input-group">
                                        <input  type={password} className={'form-control'} />
                                        <span className="input-group-text"><i className="bi bi-eye-fill fs-6"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label className="label">Confirm-Password</label>
                                    <div className="input-group">
                                        <input  type={password} className={'form-control'} />
                                        <span className="input-group-text"><i className="bi bi-eye-fill fs-6"></i></span>
                                    </div>
                                </div>
                            </div>
                           </div>
                            <div className="mb-3">
                                <Button variant="contained" size="medium">Registration</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Registration;