// import logo from './logo.svg';
import './App.css';
import Home from './template/front-end/home';
import About from './template/front-end/about-us'
import { Route, Routes  } from 'react-router-dom';
import Header from './template/inclide/header';
import Loginform from './template/front-end/login-form';
import Registration from './template/front-end/registration';
import ForgotPassword from './template/front-end/forgot-password';
import ResetPass from './template/front-end/reset-password';


function App() {

  return (
    <>
    <Header />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/login" element={<Loginform />}/>
        <Route path="/registration" element={<Registration />}/>
        <Route path="/forgot-password" element={<ForgotPassword />}/>
        <Route path="/reset-password" element={<ResetPass />}/>
      </Routes>
    </>
  );
}

export default App;
